/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      /* START_ROUTES */{
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/Dashboard'))
      },{
        path: '/dashboard/:id',
        exact: true,
        component: lazy(() => import('src/views/Dashboard/DashboardDetails'))
      },{
        path: '/sites',
        exact: true,
        component: lazy(() => import('src/views/Sites'))
      },{
        path: '/sites/:id',
        exact: true,
        component: lazy(() => import('src/views/Sites/SitesDetails'))
      },{
        path: '/sitesalary',
        exact: true,
        component: lazy(() => import('src/views/Sitesalary'))
      },{
        path: '/sitesalary/:id',
        exact: true,
        component: lazy(() => import('src/views/Sitesalary/SitesalaryDetails'))
      },{
        path: '/stores',
        exact: true,
        component: lazy(() => import('src/views/Stores'))
      },{
        path: '/stores/:id',
        exact: true,
        component: lazy(() => import('src/views/Stores/StoresDetails'))
      },{
        path: '/items',
        exact: true,
        component: lazy(() => import('src/views/Items'))
      },{
        path: '/items/:id',
        exact: true,
        component: lazy(() => import('src/views/Items/ItemsDetails'))
      },{
        path: '/stock/in',
        exact: true,
        component: lazy(() => import('src/views/StockIn'))
      },{
        path: '/stock/in/:id',
        exact: true,
        component: lazy(() => import('src/views/StockIn/StockInDetails'))
      },{
        path: '/employees',
        exact: true,
        component: lazy(() => import('src/views/Employees'))
      },{
        path: '/employees/:id',
        exact: true,
        component: lazy(() => import('src/views/Employees/EmployeesDetails'))
      },{
        path: '/capitals',
        exact: true,
        component: lazy(() => import('src/views/Capitals'))
      },{
        path: '/capitals/:id',
        exact: true,
        component: lazy(() => import('src/views/Capitals/CapitalsDetails'))
      },{
        path: '/vendors',
        exact: true,
        component: lazy(() => import('src/views/Vendors'))
      },{
        path: '/vendors/:id',
        exact: true,
        component: lazy(() => import('src/views/Vendors/VendorsDetails'))
      },{
        path: '/loans',
        exact: true,
        component: lazy(() => import('src/views/Loans'))
      },{
        path: '/loans/:id',
        exact: true,
        component: lazy(() => import('src/views/Loans/LoansDetails'))
      },{
        path: '/income',
        exact: true,
        component: lazy(() => import('src/views/Income'))
      },{
        path: '/income/:id',
        exact: true,
        component: lazy(() => import('src/views/Income/IncomeDetails'))
      },{
        path: '/expenses',
        exact: true,
        component: lazy(() => import('src/views/Expenses'))
      },{
        path: '/expenses/:id',
        exact: true,
        component: lazy(() => import('src/views/Expenses/ExpensesDetails'))
      },{
        path: '/partners',
        exact: true,
        component: lazy(() => import('src/views/Partners'))
      },{
        path: '/partners/:id',
        exact: true,
        component: lazy(() => import('src/views/Partners/PartnersDetails'))
      },{
        path: '/partnerexpenses',
        exact: true,
        component: lazy(() => import('src/views/Partnerexpenses'))
      },{
        path: '/partnerexpenses/:id',
        exact: true,
        component: lazy(() => import('src/views/Partnerexpenses/PartnerexpensesDetails'))
      },{
        path: '/salary',
        exact: true,
        component: lazy(() => import('src/views/Salary'))
      },{
        path: '/salary/:id',
        exact: true,
        component: lazy(() => import('src/views/Salary/SalaryDetails'))
      },{
        path: '/reports',
        exact: true,
        component: lazy(() => import('src/views/Reports'))
      },{
        path: '/reports/:id',
        exact: true,
        component: lazy(() => import('src/views/Reports/ReportsDetails'))
      },{
        path: '/users',
        exact: true,
        component: lazy(() => import('src/views/Users'))
      },{
        path: '/users/permissions',
        exact: true,
        component: lazy(() => import('src/views/UsersPermissions'))
      },{
        path: '/users/permissions/:id',
        exact: true,
        component: lazy(() => import('src/views/UsersPermissions/UsersPermissionsDetails'))
      },{
        path: '/users/roles',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles'))
      },{
        path: '/users/roles/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles/UserManagementRolesDetails'))
      },{
        path: '/users/users',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers'))
      },{
        path: '/users/users/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers/UserManagementUsersDetails'))
      },
	/* END_ROUTES */








      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
]
