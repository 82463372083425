/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';

import {FormattedMessage} from "react-intl"

export default [
  {
    items: [
      {
        title: "لوحة التحكم",
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: "المواقع",
        href: '/sites',
        icon: LocationCityOutlinedIcon
      },
      {
        title: "المرتبات",
        href: '/sitesalary',
        icon: AttachMoneyOutlinedIcon
      },
      {
        title: "المخازن ",
        href: '/stores',
        icon: StoreOutlinedIcon,
        items:
          [
            {
              title: "مخازن المواقع",
              href: '/stores'
            },
            {
              title: "المخزن الرئيسي",
              href: '/items'
            },
            {
              title: "فواتير التوريد للمخزن الرئيسي",
              href: '/stock/in'
            },
          ]
      },
      {
        title: "الموظفين",
        href: '/employees',
        icon: PermContactCalendarOutlinedIcon
      },
      {
        title: "العهدة",
        href: '/capitals',
        icon: MonetizationOnOutlinedIcon
      },
      {
        title: "الموردين",
        href: '/vendors',
        icon: SupervisorAccountOutlinedIcon
      },
      {
        title: "السلف",
        href: '/loans',
        icon: LocalAtmOutlinedIcon
      },
      {
        title: "الرصيد الوارد",
        href: '/income',
        icon: AssistantOutlinedIcon
      },
      {
        title: "المصروفات",
        href: '/expenses',
        icon: MoneyOutlinedIcon
      },
      {
        title: "الشركاء",
        href: '/partners',
        icon: ContactMailOutlinedIcon
      },
      {
        title: "مصروفات الشركاء",
        href: '/partnerexpenses',
        icon: AccountBalanceWalletOutlinedIcon
      },
      {
        title: "الرواتب",
        href: '/salary',
        icon: AttachMoneyOutlinedIcon
      },
      // {
      //   title: "التقارير",
      //   href: '/reports',
      //   icon: AssignmentOutlinedIcon
      // },
      {
        title: "الإدارة ",
        href: '/users',
        icon: GroupAddOutlinedIcon,
        items:
          [
            {
              title: "الصلاحيات",
              href: '/users/roles'
            },
            {
              title: "أعضاء الادارة",
              href: '/users/users'
            }
          ]
      },
    ]
  }
]
