import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  authLogin: ['loggedIn', 'user', 'permissions', 'token'],
  authLogout: null,
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loggedIn: false,
  user: {},
  permissions: {},
  token: ''
})

/* ------------- Reducers ------------- */
export const login = (state = INITIAL_STATE, {loggedIn, user, permissions, token}) => {
  return {...state, loggedIn, user, permissions, token}
}

export const logout = (state = INITIAL_STATE) => {
  return INITIAL_STATE
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_LOGIN]: login,
  [Types.AUTH_LOGOUT]: logout,
})
