import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import typography_rtl from "./typography_rtl"

const baseTheme = {
  palette,
  typography,
  overrides
};

export const theme = createMuiTheme(baseTheme);
export const themeWithRtl = createMuiTheme({ ...baseTheme, typography: typography_rtl, direction: 'rtl' });
