import {applyMiddleware, createStore, compose} from 'redux'
import reducers from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default function configureStore(initialState) {

  const middleware = []
  const enhancers = []

  enhancers.push(applyMiddleware(...middleware))

  let store = createStore(persistedReducer, initialState, compose(...enhancers))
  let persistor = persistStore(store)

  return { store, persistor }
}
