import logo from './logo'

const baseDomain = 'https://api.el-soudi.com'
const baseUrl = baseDomain + '/v1'
const authables = []
const currency = 'EGP'

// Form Options
export const formOptions = {
  keepData: true,
  clearInputs: true
}

// PDF Options
export const pdfOptions = {
  allowPrint: true,
  logo: 'logo_white.png',
  logoStyle: {
    width: 200,
  },
  company: 'شركة السعودي',
}

// Global Options
export const globalOptions = {
  enableAccessGuard: true
}

export {
  baseUrl,
  baseDomain,
  authables,
  currency
}
