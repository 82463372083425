import Drawer from "@material-ui/core/Drawer"
import React, {Component, createElement, lazy, memo} from "react"
import {makeStyles} from "@material-ui/styles"

const drawerWidth = 1000

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    maxWidth: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer + 200,
    maxWidth: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  },
  filterTitle: {
    padding: theme.spacing(0, 3),
    marginTop: -25
  },
  formContainer: {
    paddingTop: theme.spacing(2),
    flex: 1
  }
}))

const DetailsDrawer = memo(({showDetails, component, onClose}) => {

  const classes = useStyles()

  return (<Drawer
    className={classes.drawer}
    variant="temporary"
    anchor="left"
    open={showDetails}
    classes={{
      paper: classes.drawerPaper,
    }}
    onBackdropClick={() => {
      onClose()
    }}
  >
    {component}
  </Drawer>)
}, (prevProps, nextProps) => prevProps.showDetails === nextProps.showDetails)



class DetailsPanel extends Component{

  state = {
    showDetails: false
  }

  constructor (props) {
      super(props)
  }

  static detailsPanelInstance

  static show (params) {
    this.detailsPanelInstance.showDetails(params)
  }

  static hide() {
    this.detailsPanelInstance.hideDetails()
  }

  showDetails(params) {
    this.setState({showDetails: true, ...params})
  }

  hideDetails() {
    this.setState({showDetails: false})
  }

  render() {
    const {showDetails, view, route} = this.state
    return (
      <DetailsDrawer showDetails={showDetails} component={view} route={route} onClose={() => {
        this.setState({showDetails: false})
        window.history.back()
      }}/>
    )
  }
}

export default DetailsPanel
